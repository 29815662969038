import React, { forwardRef } from "react"
import NextLink from "next/link"
import cn from "classnames"
import Icon, { IconType } from "../icon/Icon"
import { Squircle } from "src/components/squircle/Squircle"

interface LinkProps {
    children: React.ReactNode
    href: string
    locale?: string | false
    target?: string
    className?: string
    appearance?: "secondary" | "dark" | "light"
    rel?: string
    style?: React.CSSProperties
    arrow?: boolean
    pill?: boolean
    prefetch?: boolean
    onClick?: (any) => void
    onMouseDown?: (any) => void
    onMouseEnter?: (any) => void
}

export const Link = forwardRef(function Link({ pill = false, arrow = false, children, href, locale, target, rel, onMouseDown, onMouseEnter, prefetch, appearance, ...props }: LinkProps, ref) {

    // NextLink does not support hash links, so we use a normal anchor tag instead
    // https://github.com/vercel/next.js/issues/44295
    const LinkComponent = (prefetch === false || href?.startsWith("#")) ? "a" : NextLink

    return (
        <Squircle
            as={LinkComponent}
            cornerRadius={pill || props.className?.includes("boxed") ? 16 : 0}
            borderWidth={appearance === "secondary" ? 1 : null}

            {...(LinkComponent === "a" ? {} : { scroll: true, prefetch: prefetch, locale: !href?.startsWith("/") ? locale : null })}
            href={href || ""}
            target={target}
            rel={rel}
            {...(href?.includes("https://support.lunar.app") ? { referrerPolicy: "no-referrer-when-downgrade" } : {})}
            {...(ref ? { ref } : {})}
            {...props}
            className={cn(props.className, {
                "button--pill": pill,
                "arrow-link--right": arrow,
                [`button--${appearance}`]: appearance,
            })}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
        >
            <>
                {children} {arrow ? <Icon type={IconType.ARROW_RIGHT} /> : null}
            </>
        </Squircle>
    )
})
