import React, { SyntheticEvent } from "react"
import { LinkItem, SanityCallToAction } from "../../sanity/types"
import { LinkHandler } from "../link-handler/LinkHandler"
import { trackGTMEvent } from "../../lib/tagmanager"

interface CallToActionProps extends SanityCallToAction {
    className?: string
    appearance?: "secondary" | "dark" | "light"
}

export default function CallToAction({ style, link, className, appearance }: CallToActionProps) {
    if (!style || !link || !link.title) {
        return null
    }

    const handleMouseDown = getCallToActionTrackingHandler(link)

    return (
        <LinkHandler link={link} className={className} pill={style === "button"} arrow={style === "link"} appearance={appearance} onMouseDown={handleMouseDown} />
    )
}

// We only care about some of the props
interface LinkForCallToActionTracking extends Omit<Partial<LinkItem>, "event"> {
    event?: Sanity.Schema.EventLink | string
}

export function getCallToActionTrackingHandler(link: LinkForCallToActionTracking) {
    return (event: SyntheticEvent) => {
        const ctaAction = link.linkType === "event" ? link.event : `${link.linkType}-link` // downloadOverlay, external-link, internal-link

        // Function to traverse up the dom tree to find the closest module
        function getEncompassingModuleNameAndIndex(ele: Element) {
            try {
                const moduleName = ele.getAttribute("data-module-name")
                const moduleIndex = ele?.parentElement?.nodeName === "MAIN" ? Array.prototype.slice.call(ele.parentElement.children).indexOf(ele) + 1 : null
                if (moduleName) return [moduleName, moduleIndex]
                if (ele.parentElement) return getEncompassingModuleNameAndIndex(ele.parentElement)
                return [null, null]
            } catch (err) { return [null, null] }
        }
        const [ctaModuleLocation, ctaModuleIndex] = getEncompassingModuleNameAndIndex(event.target as Element)
        function findCtaPosition(ele: Element) {
            const tagNameMapToPosition = {
                HEADER: "navigation",
                MAIN: "body",
                FOOTER: "footer",
            }
            if (Object.keys(tagNameMapToPosition).includes(ele.tagName))
                return tagNameMapToPosition[ele.tagName]

            return ele.parentElement ? findCtaPosition(ele.parentElement) : null
        }
        const ctaPagePosition = findCtaPosition(event.target as Element)

        trackGTMEvent("cta-click", {
            "cta-action": ctaAction,
            "cta-button-text": link.title,
            "cta-module-location": ctaModuleLocation,
            "cta-page-position": ctaPagePosition,
            "cta-module-index": ctaModuleIndex,
        })
    }
}
