import React, { useRef } from "react"
import cn from "classnames"
import s from "./Menu.module.scss"
import { LinkChild } from "src/utils"
import { LinkHandler } from "../../components/link-handler/LinkHandler"
import CallToAction from "src/components/call-to-action/CallToAction"
import Media from "src/components/media/Media"
import { useSettings } from "../page/settingsContext"
import Icon, { IconType } from "src/components/icon/Icon"
import { Container } from "../container/Container"

interface MenuProps {
    label: string
    children: LinkChild[]
    id: string
    isActive?: boolean
    big?: boolean
    onMouseEnter?: () => void
}

// is element contained in target
export function containsElement(target: HTMLElement, element: HTMLElement) {
    let e = element.parentNode

    while (e) {
        if (e === target) {
            return true
        }

        e = e.parentNode
    }

    return false
}

export default function Menu({
    label,
    children = [],
    id,
    isActive,
    big = false,
    onMouseEnter,
}: MenuProps) {
    const ref = useRef<HTMLDivElement>()
    const refOuter = useRef<HTMLDivElement>()
    // const menu = buildMenus([...(children || [])])
    // const cleanMenu = cleanBuiltMenus(menu)

    return (
        <>
            <div
                className={cn(s["menu"], isActive ? s["menu--is-active"] : null)}
                onMouseEnter={onMouseEnter}
                ref={refOuter}
            >
                <button
                    className={cn(s["menu__button"], "label-100")}
                    aria-controls={"menu-" + id}
                >
                    {label}
                    <Icon type={IconType.CHEVRON_DOWN} />
                </button>

            </div>
            <div
                className={cn(s["menu__popup"], {
                    [s["menu__popup--big"]]: big,
                    [s["menu__popup--active"]]: isActive,
                })}
                ref={ref}
                id={"menu-" + id}
            >

                <Container noMargin={!big}>
                    <ul className={cn(s["menu__popup__list"])}>
                        {[...(children || [])].map((menuItem, index: number) => {
                            const delay = ((index + 0) * 0.0) + "s"

                            if (menuItem.type === "linkCollector") {
                                return (
                                    <ul key={menuItem.type + index} className={s["menu__popup__collector"]}>
                                        {menuItem.children.map((child, childIndex: number) => {
                                            return (
                                                <LinkDisplay
                                                    key={child.type + childIndex}
                                                    menuItem={child}
                                                    delay={delay}
                                                    big={big}
                                                />
                                            )
                                        })}
                                    </ul>
                                )
                            } else {
                                return (
                                    <LinkDisplay
                                        key={menuItem.type + index}
                                        menuItem={menuItem}
                                        delay={delay}
                                        big={big}
                                        countOfLinkGroups={children?.length}
                                    />
                                )
                            }
                        })}
                    </ul>
                </Container>
            </div>
        </>
    )
}

interface LinkDisplayProps {
    menuItem: LinkChild
    delay: string
    big: boolean
    countOfLinkGroups?: number
    arrow?: boolean
}

export const LinkDisplay = ({ menuItem, delay, big, countOfLinkGroups }: LinkDisplayProps) => {
    const { translations: t } = useSettings()

    // Temp disabled on Jan 21, 2025 to see if anyone complains – I'd rather not have this logic
    // Hide crypto on pages from Google Ads - either manually added param "nc" (No Crypto), or automatically added "gclid" param
    // const shouldHideCrypto = typeof window !== "undefined" && (location.search.includes("nc") || location.search.includes("gclid"))

    if (menuItem.type === "linkGroup") {
        // Logic to decide how many rows should be in the grid
        // If the link group consist of feature links, we only do 1 row
        // If (the link group is less than 5) or (there is less than 3 groups in the dropdown), we do 1 column
        // Else we do 2 columns
        const breakIntoTwoColumns = menuItem?.children?.length > 5 || (menuItem?.children?.length > 4 && countOfLinkGroups < 3)

        return (
            <li
                key={menuItem.id}
                className={cn(
                    s["menu__popup__item"],
                    {
                        [s["menu__popup__item--small"]]: big,
                        [s["menu__popup__item--feature"]]: menuItem.children?.[0].type === "link" && menuItem.children?.[0].isFeatureLink,
                        [s["menu__popup__item--two-columns"]]: breakIntoTwoColumns,
                    })}
                style={{
                    animationDelay: delay,
                }}
            >
                {big && (
                    <div className={s["menu__popup__heading"]}>
                        <strong className={cn("label-100")}>
                            {menuItem.label}
                        </strong>
                    </div>
                )}

                <ul className={s["menu__popup__sublist"]} style={{
                    "gridTemplateRows":
                        menuItem.children?.[0]?.type === "link" && menuItem.children?.[0]?.isFeatureLink ? "1fr"
                            : (
                                breakIntoTwoColumns
                                    ? "auto ".repeat(Math.ceil((menuItem.children.length) / 2))
                                    : "auto ".repeat(menuItem.children.length)
                            ),
                }}>
                    {[...menuItem.children].map((menuItemChild: LinkChild, childIndex) => {
                        if (menuItemChild.type === "linkCollector") {
                            return (
                                <ul key={menuItemChild.type + childIndex} className={s["menu__popup__collector"]}>
                                    {menuItemChild.children.map((collectorChild, collectorIndex: number) => {
                                        return (
                                            <LinkDisplay
                                                key={collectorChild.type + collectorIndex}
                                                menuItem={collectorChild}
                                                delay={delay}
                                                big={big}
                                            />
                                        )
                                    })}
                                </ul>
                            )
                        }
                        if (menuItemChild.type === "linkGroup") {
                            return (
                                <LinkDisplay key={menuItemChild.id} big={big} delay={delay} menuItem={menuItemChild} />
                            )

                        }

                        // const isCryptoLink = menuItemChild.type === "link" && menuItemChild.linkType === "internal" && menuItemChild.url.slug.includes("rypto")
                        // if (shouldHideCrypto && isCryptoLink) return null

                        return (
                            <LinkHandler link={menuItemChild} key={menuItemChild.id} >
                                <li className={cn(
                                    s["menu__popup__sublist__item"],
                                    s["menu__link"],
                                    menuItemChild.isFeatureLink ? s["menu__popup__sublist__item--feature"] : null,
                                )}>
                                    {(menuItemChild?.media?.type === "image" || menuItemChild?.media?.type === "icon") && (
                                        <div className={s["menu__popup__sublist__item__media"]}>
                                            <Media media={menuItemChild.media} sizes={[menuItemChild.isFeatureLink ? "250px" : "26px"]} width={menuItemChild.media.width} height={menuItemChild.media.height} />
                                        </div>
                                    )}
                                    <div className={s["menu__popup__sublist__item__text"]}>
                                        <span className="label-100">
                                            {menuItemChild.title}
                                            {menuItemChild.markAsNew && (
                                                <span className={cn("bg-accent", s["menu__popup__sublist__item__new-label"])}>{t.header.markAsNew}</span>
                                            )}
                                        </span>
                                        <span className={s["menu__popup__sublist__item__subtitle"]}>
                                            {menuItemChild.subtitle}
                                        </span>
                                    </div>
                                </li>
                            </LinkHandler>
                        )
                    })}
                </ul>
                {menuItem.cta?.style && (
                    <CallToAction {...menuItem.cta} className={cn(s["menu__popup__item__cta"])} />
                )}
            </li>
        )
    }

    // very special link handling for elements in big popup
    if (menuItem.type === "link") {
        return (
            <li
                style={{
                    animationDelay: delay,
                }}
                key={menuItem.id}
                className={cn(
                    s["menu__popup__item--solo"],
                    s["menu__popup__item"],
                    s["menu__link"],
                )}
            >
                {/* Title and subtitle */}
                <LinkHandler className={cn({ "label-100": !menuItem.withArrow })} link={menuItem} />
            </li>
        )
    }
}
