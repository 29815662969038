import React, { ReactNode } from "react"
import cn from "classnames"
import Icon, { IconType } from "../icon/Icon"
import { Squircle } from "src/components/squircle/Squircle"

interface ButtonProps {
    children: ReactNode
    arrow?: boolean
    arrowLeft?: boolean
    pill?: boolean
    onClick?: (any) => void
    className?: string
    appearance?: "secondary" | "dark" | "light"
    type?: "button" | "submit" | "reset"
    center?: boolean
    disabled?: boolean
    onMouseDown?: (any) => void
    onMouseEnter?: (any) => void
}

export const Button = ({
    children,
    pill = false,
    center = false,
    arrow = false,
    arrowLeft = false,
    disabled = false,
    appearance = null,
    type = "button",
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClick = () => { },
    onMouseDown,
    onMouseEnter,
    ...props
}: ButtonProps): JSX.Element => {
    return (
        <Squircle
            cornerRadius={20}
            borderWidth={appearance === "secondary" ? 1 : null}
            as="button"
            type={type}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
            className={cn(props.className, "label-100", {
                "button--pill": pill,
                "button--center": center,
                "arrow-link--right": arrow,
                "arrow-link--left": arrowLeft,
                [`button--${appearance}`]: appearance,
            })}
            onClick={onClick}
            disabled={disabled}
        >
            {arrowLeft ? <Icon type={IconType.ARROW_LEFT} /> : null}
            {children}
            {arrow ? <Icon type={IconType.ARROW_RIGHT} /> : null}
        </Squircle>
    )
}
