import { Container } from "src/layout/container/Container"
import { BaseModule, InternalLink, SanityImage } from "src/sanity/types"
// import { CATEGORIES } from "src/../../studio/schemas/documents/blogPost"

// We query for mainImage and modules in a specific way, so we manually define them
export interface QueriedBlogPost extends Omit<Sanity.Schema.BlogPost, "mainImage" | "modules" | "category" | "slug"> {
    mainImage: SanityImage
    link: InternalLink
    slug: string
    updatedAt?: string
    category: {
        title: string
        englishTitle: string
        slug: string
        _id: string
    }
}

export interface TeaserBlogPost extends Pick<QueriedBlogPost, "title" | "category" | "link" | "mainImage" | "lang" | "segment" | "slug" | "publishedAt" | "intro"> {
    id?: string
    emptyProp?: null
}

export function getUrlForNextPageInBlogOverview(blogOverviewUrl: string) {
    // Construct link for next page
    if (!blogOverviewUrl.includes("blog")) return null
    const url = new URL(blogOverviewUrl)

    const pathParts = url.pathname.split("/")
    const currentPage = pathParts.slice(-1)[0].startsWith("p-") ? parseInt(pathParts.slice(-1)[0].split("-")[1], 10) : 1
    const nextPagePath = pathParts.slice(0, 4).concat("p-" + (currentPage + 1)).join("/")
    url.pathname = nextPagePath
    return url.toString()
}

export const slugify = (str: string) =>
    typeof str !== "string" ? str : str
        .toLowerCase()
        .trim()
        .replace(/å/g, "aa")
        .replace(/æ/g, "ae")
        .replace(/ø/g, "oe")
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "")


export const BlogTableOfContents = ({ modules, title, className }: { modules: BaseModule[], title: string, className: string }) => {
    // Get all h2 out of all RichTextModules in a list of Modules
    const richTextModules = (modules || []).filter(m => m.type === "richTextModule")
    const headers = richTextModules.map(richTextModule => {
        return richTextModule.body.map(block => block.style === "h2" ? { headerLevel: block.style, text: block.children?.[0]?.text, slug: slugify(block.children?.[0]?.text) } : null).filter(Boolean)
    }).filter(module => module.length > 0).flat(3)

    if (headers.length < 2) return null

    return (
        <Container>
            <div className={className}>
                <b>{title}</b>
                <ul>
                    {headers.map((header, i) => (
                        <li key={i + header.slug}><a href={"#" + header.slug} onClick={(e) => { e.preventDefault(); window.scrollTo(0, document.getElementById(header.slug).offsetTop - 100) }}>{header.text}</a></li>
                    ))}
                </ul>
            </div>
        </Container>
    )
}
