import React, { useState } from "react"
import { Link } from "src/components/link/Link"
import { Container } from "../container/Container"

import { PageReference, InternalLink } from "src/sanity/types"
import LanguageSelector from "src/components/language-selector/LanguageSelector"
import Icon, { IconType } from "src/components/icon/Icon"
import SoMeLinksBlock from "src/components/so-me-links-block/SoMeLinksBlock"
import RichText from "src/components/rich-text/RichText"
import { buildMenus, cleanBuiltMenus, LinkChild, Only } from "src/utils"
import cn from "classnames"
import buildLink from "../../sanity/buildLink"
import { useSettings } from "../page/settingsContext"
import { LinkHandler } from "../../components/link-handler/LinkHandler"
import s from "./Footer.module.scss"
import AppStore from "src/components/app-store/AppStore"
import { useOverwrites } from "../page/overwriteContext"
import SegmentSelector from "../header/SegmentSelector"

interface FooterProps {
    pageReferences?: PageReference[]
    isMoonriseSite?: boolean
}

export function Footer({ pageReferences, isMoonriseSite }: FooterProps): JSX.Element {
    const {
        translations: t,
        lang,
        segment,
        navigation,
        legalText,
        soMeNav,
    } = useSettings()

    if (isMoonriseSite) {
        navigation.footer = [
            {
                id: "linkCollector1",
                type: "linkGroup",
                label: "B2B Banking services",
                groupWithPrevious: false,
                children: [
                    {
                        id: "link2",
                        title: "Product: Accounts",
                        type: "link",
                        linkType: "external",
                        url: "/accounts",
                    },
                    {
                        id: "link3",
                        title: "Product: Payments",
                        type: "link",
                        linkType: "external",
                        url: "/payments",
                    },
                    {
                        id: "link4",
                        title: "Use cases",
                        type: "link",
                        linkType: "external",
                        url: "/use-cases",
                    },
                    {
                        id: "link5",
                        title: "About Moonrise",
                        type: "link",
                        linkType: "external",
                        url: "/about",
                    },
                    {
                        id: "link6",
                        title: "Explore our API (For Developers)",
                        type: "link",
                        linkType: "external",
                        url: "https://docs.banking-services.lunar.app",
                    },
                    {
                        id: "link7",
                        title: "Get in touch",
                        type: "link",
                        linkType: "event",
                        event: "downloadOverlay",
                    },
                    {
                        id: "link8",
                        title: "Operating status",
                        type: "link",
                        linkType: "external",
                        url: "https://status.banking-services.lunar.app",
                    },
                ],

            },
            {
                id: "linkGroup2",
                type: "linkGroup",
                label: "Lunar Bank",
                groupWithPrevious: false,
                children: [
                    {
                        id: "link2",
                        title: "Lunar Bank",
                        type: "link",
                        linkType: "internal",
                        url: {
                            segment: "private",
                            lang: "en",
                            slug: null,
                        },
                    },
                    {
                        id: "link3",
                        title: "Lunar Business",
                        type: "link",
                        linkType: "internal",
                        url: {
                            segment: "business",
                            lang: "en",
                            slug: null,
                        },
                    },
                ],

            },
            {
                id: "linkGroup3",
                type: "linkGroup",
                label: "About us",
                groupWithPrevious: false,
                children: [
                    {
                        id: "link2",
                        title: "About Lunar",
                        type: "link",
                        linkType: "internal",
                        url: {
                            segment: "private",
                            lang: "en",
                            slug: "what-is-lunar",
                        },
                    },
                    {
                        id: "link3",
                        title: "News and press",
                        type: "link",
                        linkType: "internal",
                        url: {
                            segment: "private",
                            lang: "en",
                            slug: "news-and-press",
                        },
                    },
                    {
                        id: "link4",
                        title: "Danish FSA (In Danish)",
                        type: "link",
                        linkType: "internal",
                        url: {
                            segment: "private",
                            lang: "dk",
                            slug: "finanstilsynet",
                        },
                    },
                    {
                        id: "link5",
                        title: "Corporate",
                        type: "link",
                        linkType: "internal",
                        url: {
                            segment: "private",
                            lang: "en",
                            slug: "corporate",
                        },
                    },
                ],

            },
        ]
    }

    let logo: IconType = IconType.LOGO
    if (segment === "private") logo = IconType.LOGO
    else if (isMoonriseSite) logo = IconType.MOONRISE_LOGO_WITH_LUNAR_CAPTION
    else if (segment === "business") logo = IconType.LOGO_BUSINESS


    const menu = buildMenus([...(navigation?.footer || [])])
    const cleanMenu = cleanBuiltMenus(menu)

    const homeLink: InternalLink = {
        id: "",
        title: "",
        linkType: "internal",
        type: "link",
        url: {
            type: "frontpage",
            lang,
            segment,
            slug: "",
        },
    }
    const { downloadOverlay: downloadOverlayOverwrite } = useOverwrites()

    return (
        <footer className={s["footer"]}>
            {/* {showFooterTopBlock ? <FooterTopBlock /> : <div style={{ height: "100px" }} /> } */}

            <div className={s["footer__navigation"]}>
                <Container>
                    <nav className={s["footer__top"]}>
                        <Link href={buildLink(homeLink)} className={s["footer__logo"]}>
                            <Icon type={logo} />
                            <span className="visually-hidden">Lunar {t?.header?.home.toLocaleLowerCase()}</span>
                        </Link>

                        {!isMoonriseSite && (
                            <>
                                <SegmentSelector dark={false} className={s["footer__segment-selector"]} />

                                <AppStore onelinkUrl={downloadOverlayOverwrite?.onelink} className={s["footer__app"]} />

                                <div className={s["footer__languages"]}>
                                    <LanguageSelector pageReferences={pageReferences} />
                                </div>
                            </>
                        )}
                    </nav>
                    <div className={s["footer__inner"]}>
                        <nav>
                            <ul className={s["footer__nav"]}>
                                {cleanMenu.map((i: LinkChild, index: number) => {
                                    if (i.type === "linkCollector") {
                                        return (
                                            <li key={i.type + "-" + index}>
                                                <ul className={s["footer__nav__link-collector"]}>
                                                    {i.children.map((e, eIndex) => (
                                                        <NavGroup navItem={e} key={e.type + "-" + eIndex} isSubMenu={false} />
                                                    ))}
                                                </ul>
                                            </li>
                                        )
                                    }

                                    if (i.type === "linkGroup") {
                                        return (
                                            <LinkGroup {...i} key={i.type + "-" + index} isSubMenu={false} />
                                        )
                                    }

                                    return (
                                        <li key={i.type + "-" + index} className={s["footer__link"]}>
                                            <LinkHandler link={i} />
                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>
                        <div className={s["footer__bottom"]}>
                            <Only if={soMeNav}>
                                <SoMeLinksBlock nav={soMeNav} />
                            </Only>
                        </div>
                        <Only if={legalText}>
                            <div className={s["footer__legal"]}>
                                <RichText blocks={legalText} className="paragraph-50" />
                            </div>
                        </Only>
                    </div>
                </Container>
            </div>
        </footer>
    )
}

interface LinkGroupProps {
    label: string
    children: LinkChild[]
    isSubMenu: boolean
}

function LinkGroup({ children, label, isSubMenu }: LinkGroupProps) {
    const [hasCollector, setHasCollector] = useState(false)

    return (
        <li className={cn(s["footer__nav__link-group"])}>
            <strong
                className={cn(s["footer__nav__link-group__title"], "label-100", {
                    [s["footer__nav__link-group__title--large"]]: !isSubMenu,
                    "label-200": !isSubMenu,
                    [s["footer__nav__link-group__title--small"]]: isSubMenu,
                })}
            >
                {label}
            </strong>

            <ul className={cn("footer__nav__link-group-ul", { [s["footer__nav__link-group-ul--collector-inside"]]: hasCollector })}>
                {children.map((child, index) => {
                    if (child.type === "linkCollector") {
                        if (!hasCollector) {
                            setHasCollector(true)
                        }
                        return (
                            <li key={child.type + "-" + index} className={s["footer__nav__link-collector-wrapper"]}>
                                <ul className={s["footer__nav__link-collector"]}>
                                    <NavGroup navItem={child} isSubMenu={true} />
                                </ul>
                            </li>
                        )
                    }

                    if (child.type === "linkGroup") {
                        return (
                            <LinkGroup {...child} key={child.id} isSubMenu={true} />
                        )
                    }

                    return (
                        <li key={child.id} className={s["footer__link"]}>
                            <LinkHandler link={child} />
                        </li>
                    )
                })}
            </ul>
        </li>
    )
}

function NavGroup({ navItem, isSubMenu }: { navItem: LinkChild, isSubMenu: boolean }) {
    if (navItem.type === "linkCollector") {
        return (
            <>
                {navItem.children.map((child, index) => (
                    <NavGroup navItem={child} key={child.type + "-" + index} isSubMenu={isSubMenu} />
                ))}
            </>
        )
    }

    if (navItem.type === "linkGroup") {
        return (
            <LinkGroup {...navItem} key={navItem.id} isSubMenu={isSubMenu} />
        )
    }

    return (
        <li key={navItem.id} className={s["footer__link"]}>
            <LinkHandler link={navItem} />
        </li>
    )
}
